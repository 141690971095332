import React, { Component } from "react";
import env from "../env";
import config from "../config";

import {
    Box,
    Button,
    Divider,
    MenuItem,
    Modal,
    Stack,
    Select,
} from '@mui/material';
import Swal from "sweetalert2";
import { TextField } from '@material-ui/core';
import { DataGrid, } from '@mui/x-data-grid';
import { ModalClose } from '@mui/joy';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 650,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default class MainTransactions extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loadingState: config.LoadingStates.loading,
            currentOperator: {},
            transaction: {},
            data: [],
            error: '',
        };

        this.initAdmin = this.initAdmin.bind(this);
        this.create = this.create.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.createTransaction = this.createTransaction.bind(this);
        this.isInvalidCreate = this.isInvalidCreate.bind(this);
        this.download = this.download.bind(this);
        this.downloadFront = this.downloadFront.bind(this);
        this.downloadBack = this.downloadBack.bind(this);
        this.downloadPdfPost = this.downloadPdfPost.bind(this);
        this.printPdf = this.printPdf.bind(this);
    }

    componentDidMount() {
        this.initAdmin();
        this.create();
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.props.modalVisible && !prevProps.modalVisible) {
            this.create();
        }
    }

    initAdmin() {
        this.setState({
            ...this.state,
            loadingState: config.LoadingStates.loading,
        });

        let { id } = this.props.currentContributer;
        let url = env.ApiBaseUrl + '/transactions/userId/' + id;
        fetch(url, {
            method: 'GET',
            headers: new Headers({
                'cache-control': "no-cache"
            })
        })
            .then(res => res.json())
            .then(
                (result) => {
                    if (result && result.length >= 0) {
                        this.setState({
                            ...this.state,
                            loadingState: config.LoadingStates.loaded,
                            data: result,
                        })
                    } else {
                        this.setState({
                            ...this.state,
                            loadingState: config.LoadingStates.error,
                        })
                    }
                },
                (error) => {
                    console.log("Eroare: ", error);
                    this.setState({
                        ...this.state,
                        loadingState: config.LoadingStates.error,
                    })
                }
            );
    }

    isInvalidCreate = () => {
        let { transaction } = this.state;
        let result = '';
        if (!transaction.userId) {
            if (result.length) { result += '; ' }
            result += 'userId'
        }
        return result;
    }

    createTransaction = () => {
        this.props.onTransactionModalChange(false);
        let isInvalidInputResult = this.isInvalidCreate();
        if (isInvalidInputResult) {
            this.setState({
                ...this.state,
                error: 'Vă rugăm să introduceți ' + isInvalidInputResult,
            });
            return;
        }
        this.setState({
            ...this.state,
            loadingState: config.LoadingStates.loading
        });
        let url = env.ApiBaseUrl + "transactions";
        fetch(url, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
                'cache-control': "no-cache"
            }),
            body: JSON.stringify(this.state.transaction),

        })
            .then(res => {
                return res.json();
            },
                () => {
                    this.setState({
                        ...this.state,
                        error: 'Tranzacția nu a fost adăugată!',
                    });
                })
            .then(data => {
                if (data && data.id) {
                    Swal.fire({
                        title: 'Salvare cu success',
                        text: 'Tranzacția a fost adăugată cu success!',
                        icon: 'success',
                    });
                    this.download(data);
                    this.initAdmin();
                }
                else {
                    this.setState({
                        ...this.state,
                        error: 'Tranzacția nu a fost adăugată!',
                    });
                }
            });
    }

    create() {
        let { currentContributer, currentOperator } = this.props;
        this.setState({
            ...this.state,
            transaction: {
                userId: currentContributer.id,
                name: currentContributer.name,
                officeNumber: currentOperator.casa,
                operator: currentOperator.name,
                transactionDate: new Date().toISOString().substring(0, 10),
                paymentMethod: 0,
                imprimateAmmount: 0,
                cotizatieAmmount: 0,
                contributieAmmount: 0,
                fadAmmount: 0,
                ajAmmount: 0,
                culturalAmmount: 0,
                administrativAmmount: 0,
                imprumutPayment: 0,
                monthCount_cotiz: 0,
                monthCount_rate: 0,
                penalizare: 0,
                dobandaEsalonata: 0,
                interestType: 0,
                imprumutTaken: 0,
                liquidationDate: '',
            },
            error: '',
        });
    }

    handleCloseModal(event, reason) {
        if (reason && reason === "backdropClick")
            return;
        this.props.onTransactionModalChange(false);
    }

    handleChange = (event, location) => {
        let { appConfigs } = this.props;
        let { transaction } = this.state;

        if (location === 'cotizatieAmmount' && event.target.value !== 0 && transaction[location] === 0) {
            if (transaction['ajAmmount'] === 0) {
                let ajAmmount = appConfigs.filter(obj => {
                    return obj.id === 1
                })[0].value;
                transaction['ajAmmount'] = ajAmmount;
            }
            if (transaction['administrativAmmount'] === 0) {
                let administrativAmmount = appConfigs.filter(obj => {
                    return obj.id === 2
                })[0].value;
                transaction['administrativAmmount'] = administrativAmmount;
            }
            if (transaction['contributieAmmount'] === 0) {
                let contributieAmmount = appConfigs.filter(obj => {
                    return obj.id === 3
                })[0].value;
                transaction['contributieAmmount'] = contributieAmmount;
            }
        }
        transaction[location] = event.target.value;

        this.setState({
            ...this.state,
            transaction: transaction,
        });
    };

    download = (location) => {
        const pdfUrl = env.ApiBaseUrl + 'receipts/generate-receipt/' + location.id;

        fetch(pdfUrl)
            .then(response => response.blob())
            .then(blob => {
                // Create a URL for the blob
                const url = window.URL.createObjectURL(blob);
                // Open the PDF in a new tab
                window.open(url, '_blank');
            })
            .catch(error => {
                console.error('Error opening PDF:', error);
            });
    };


    downloadFront = () => {
        this.downloadPdfPost('fata');
    }

    downloadBack = () => {
        this.downloadPdfPost('spate');
    }

    downloadPdfPost = (location) => {
        let url = env.ApiBaseUrl + "documents/" + location;

        let downloadPath = 'Tranzactie' +
            new Date().toISOString().substring(0, 10) +
            '_' +
            new Date().toISOString().substring(11, 23) +
            '_' +
            location +
            '.pdf';

        let isInvalidInputResult = this.isInvalidCreate();
        if (isInvalidInputResult) {
            this.setState({
                ...this.state,
                error: 'Vă rugăm să introduceți ' + isInvalidInputResult,
            });
            return;
        }
        fetch(url, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
                'cache-control': "no-cache"
            }),
            body: JSON.stringify(this.state.transaction),

        })
            .then(response => response.blob())
            .then(blob => {
                // Create a URL for the blob
                const url = window.URL.createObjectURL(new Blob([blob]));
                // Create a temporary link element
                const a = document.createElement('a');
                a.href = url;
                a.download = downloadPath;
                // Append the link to the body
                document.body.appendChild(a);
                // Click the link
                a.click();
                // Remove the link from the body
                document.body.removeChild(a);
            })
            .catch(error => {
                console.error('Error downloading PDF:', error);
            });
    }

    printPdf = async (location) => {
        const pdfUrl = env.ApiBaseUrl + 'receipts/generate-receipt/' + location.id;

        try {
            const response = await fetch(pdfUrl);
            const blob = await response.blob();
            const blobUrl = URL.createObjectURL(blob);

            const iframe = document.createElement("iframe");
            iframe.style.position = "fixed";
            iframe.style.right = "0";
            iframe.style.bottom = "0";
            iframe.style.width = "0";
            iframe.style.height = "0";
            iframe.src = blobUrl;

            document.body.appendChild(iframe);

            iframe.onload = () => {
                iframe.contentWindow.focus();
                iframe.contentWindow.print();
                URL.revokeObjectURL(blobUrl); // Clean up URL
                document.body.removeChild(iframe); // Remove iframe after printing
            };
        } catch (error) {
            console.error("Error fetching PDF:", error);
        }
    }

    render() {
        let { currentContributer } = this.props;
        let { transaction, error } = this.state;

        let cotisationPayedUntil = currentContributer.cotisationPayedUntil;
        if (currentContributer.cotisationPayedUntil) {
            const [year, month, day] = currentContributer.cotisationPayedUntil.split("-").map(Number);
            const date = new Date(year, month - 1, day); // Month is 0-indexed in JS
            date.setMonth(date.getMonth() + Number(transaction.monthCount_cotiz));

            const updatedYear = date.getFullYear();
            const updatedMonth = String(date.getMonth() + 1).padStart(2, "0");
            const updatedDay = String(date.getDate()).padStart(2, "0");

            cotisationPayedUntil = `${updatedYear}-${updatedMonth}-${updatedDay}`;
        }
        const loanBalance = currentContributer.loanBalance - transaction.imprumutPayment;
        const cotisationAmmount = currentContributer.cotisationAmmount + transaction.cotizatieAmmount * transaction.monthCount_cotiz;
        const columns = [
            { field: 'id', headerName: 'ID tranz.', width: 100 },
            { field: 'officeNumber', headerName: 'Casa', width: 50 },
            { field: 'transactionDate', headerName: 'Data', width: 150 },
            {
                field: 'action1',
                headerName: 'Descarcă',
                width: 200,
                sortable: false,
                disableClickEventBubbling: true,

                renderCell: (params) => {
                    return (
                        <Stack direction="row" spacing={2}>
                            <Button variant="outlined" size="small" onClick={() => this.download(params.row)}>Descarcă chitanța</Button>
                        </Stack>
                    );
                },
            },
            {
                field: 'action2',
                headerName: 'Printează',
                width: 200,
                sortable: false,
                disableClickEventBubbling: true,

                renderCell: (params) => {
                    return (
                        <Stack direction="row" spacing={2}>
                            <Button variant="outlined" size="small" onClick={() => this.printPdf(params.row)}>Printează</Button>
                        </Stack>
                    );
                },
            }
        ]

        const grid = <Box sx={{ height: 380, width: '100%' }}>
            <DataGrid
                rowHeight={25}
                rows={this.state.data}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 40,
                        },
                    },
                    sorting: {
                        sortModel: [{ field: 'id', sort: 'desc' }],
                    },
                }}
                pageSizeOptions={config.DataGrid.PageSizeOptions}
            />
        </Box>

        const transactionModal = <Modal
            open={this.props.modalVisible}
            className='transaction-modal'
            onClose={this.handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <ModalClose onClick={this.handleCloseModal} />
                <Divider className="blue inline" textAlign="left">Detalii generale</Divider>
                <div className="c-manager-div">
                    Operator: &nbsp;&nbsp;<div className="blue inline">{transaction.operator}</div>
                </div>
                <div className="c-manager-div">
                    Casa: &nbsp;&nbsp;<div className="blue inline">{transaction.officeNumber}</div>
                </div>
                <br />
                <div className="c-manager-div">
                    Contribuabil: &nbsp;&nbsp;<div className="blue inline">{transaction.name}</div>
                </div>
                <div className="c-manager-div">
                    Data: &nbsp;&nbsp;<div className="blue inline">{transaction.transactionDate?.split('-').reverse().join('-') || ''}</div>
                </div>
                <br />
                <br />
                <div className="c-manager-long">
                    Până când s-a plătit cotizația: &nbsp;&nbsp;
                    <div className="blue inline">
                        {cotisationPayedUntil?.split('-').reverse().join('-') || ''}
                    </div>
                </div>
                <div className="c-manager-long">
                    Data incasării ultimei cotizații: &nbsp;&nbsp;
                    <div className="blue inline">
                        {transaction.transactionDate?.split('-').reverse().join('-') || ''}
                    </div>
                </div>
                <div className="c-manager-div red">
                    Ultima cotizație (casă): &nbsp;&nbsp;
                    <div className="blue inline">
                        {currentContributer.cotisation_c}
                    </div>
                </div>
                <div className="c-manager-div">
                    Ultima cotizație (bancă): &nbsp;&nbsp;
                    <div className="blue inline">
                        {currentContributer.cotisation_b}
                    </div>
                </div>
                <br />
                <div className="c-manager-long red">
                    Sold cotizație: &nbsp;&nbsp;
                    <div className="blue inline">
                        {cotisationAmmount}
                    </div>
                </div>
                <div className="c-manager-div">
                    Data acordării împrumutului: &nbsp;&nbsp;
                    <div className="blue inline">
                        {currentContributer.loanStartDate?.split('-').reverse().join('-') || '-'}
                    </div>
                </div>
                <div className="c-manager-div red">
                    Împrumutul acordat: &nbsp;&nbsp;
                    <div className="blue inline">
                        {currentContributer.loanAmmount}
                    </div>
                </div>
                <div className="c-manager-div">
                    Nr. rate lunare: &nbsp;&nbsp;
                    <div className="blue inline">
                        {currentContributer.loanMonthsCount}
                    </div>
                </div>
                <div className="c-manager-div red">
                    Valoarea unei rate: &nbsp;&nbsp;
                    <div className="blue inline">
                        {currentContributer.monthlyLoanPayment}
                    </div>
                </div>
                <br />
                <div className="c-manager-div">
                    Data lichidării împrumutului: &nbsp;&nbsp;
                    <div className="blue inline">
                        {currentContributer.loanLiquidationDate?.split('-').reverse().join('-') || '-'}
                    </div>
                </div>
                <div className="c-manager-div">
                    Dobânda anuală percepută %: &nbsp;&nbsp;
                    <div className="blue inline">
                        {currentContributer.yearlyInterestRate}
                    </div>
                </div>
                <br />
                <div className="c-manager-div">
                    Dobânda reținută: &nbsp;&nbsp;
                    <div className="blue inline">
                        {currentContributer.recievedInterest}
                    </div>
                </div>
                <div className="c-manager-div red">
                    Dobândă eșalonată %: &nbsp;&nbsp;
                    <div className="blue inline">
                        {currentContributer.floatingInterest}
                    </div>
                </div>
                <div className="c-manager-long">
                    Data ultimei restituiri: &nbsp;&nbsp;
                    <div className="blue inline">
                        {transaction.transactionDate?.split('-').reverse().join('-') || '-'}
                    </div>
                </div>
                <div className="c-manager-div">
                    Ultima restituire (casă): &nbsp;&nbsp;
                    <div className="blue inline">
                        {currentContributer.returnedLoanAmmount_c}
                    </div>
                </div>
                <div className="c-manager-div">
                    Ultima restituire (bancă): &nbsp;&nbsp;
                    <div className="blue inline">
                        {currentContributer.returnedLoanAmmount_b}
                    </div>
                </div>
                <br />
                <div className="c-manager-div">
                    Dobânda recalculată: &nbsp;&nbsp;
                    <div className="blue inline">
                        {currentContributer.recalculatedInterestRate}
                    </div>
                </div>
                <div className="c-manager-div">
                    Sold împrumut: &nbsp;&nbsp;
                    <div className="blue inline">
                        {loanBalance}
                    </div>
                </div>
                <br />
                <div className="c-manager-div">
                    Diferență dobândă: &nbsp;&nbsp;
                    <div className="blue inline">
                        {currentContributer.interestDifference}
                    </div>
                </div>
                <br />
                <br />
                <Divider className="blue inline" textAlign="left">Tranzacție</Divider>
                <TextField
                    className="c-manager"
                    type="number"
                    InputLabelProps={{ shrink: true }}
                    label="Cotizație"
                    onFocus={event => { event.target.select(); }}
                    value={transaction.cotizatieAmmount}
                    maxLength={100}
                    size="small"
                    onChange={e => this.handleChange(e, "cotizatieAmmount")} />
                <TextField
                    className="c-manager"
                    type="number"
                    InputLabelProps={{ shrink: true }}
                    label="Număr luni cotizație"
                    onFocus={event => { event.target.select(); }}
                    value={transaction.monthCount_cotiz}
                    maxLength={100}
                    size="small"
                    onChange={e => this.handleChange(e, "monthCount_cotiz")} />
                <br />
                <TextField
                    className="c-manager"
                    type="number"
                    InputLabelProps={{ shrink: true }}
                    label="Imprimate"
                    onFocus={event => { event.target.select(); }}
                    value={transaction.imprimateAmmount}
                    maxLength={100}
                    size="small"
                    onChange={e => this.handleChange(e, "imprimateAmmount")} />
                <TextField
                    className="c-manager"
                    type="number"
                    InputLabelProps={{ shrink: true }}
                    label="Contribuție"
                    onFocus={event => { event.target.select(); }}
                    value={transaction.contributieAmmount * transaction.monthCount_cotiz}
                    maxLength={100}
                    size="small"
                    onChange={e => this.handleChange(e, "contributieAmmount")} />
                <br />
                <TextField
                    className="c-manager"
                    type="number"
                    InputLabelProps={{ shrink: true }}
                    label="Ajutor social"
                    onFocus={event => { event.target.select(); }}
                    value={transaction.ajAmmount * transaction.monthCount_cotiz}
                    maxLength={100}
                    size="small"
                    onChange={e => this.handleChange(e, "ajAmmount")} />
                <TextField
                    className="c-manager"
                    type="number"
                    InputLabelProps={{ shrink: true }}
                    label="Cultural"
                    onFocus={event => { event.target.select(); }}
                    value={transaction.culturalAmmount}
                    maxLength={100}
                    size="small"
                    onChange={e => this.handleChange(e, "culturalAmmount")} />
                <br />
                <TextField
                    className="c-manager"
                    type="number"
                    InputLabelProps={{ shrink: true }}
                    label="Administrativ"
                    onFocus={event => { event.target.select(); }}
                    value={transaction.administrativAmmount * transaction.monthCount_cotiz}
                    maxLength={100}
                    size="small"
                    onChange={e => this.handleChange(e, "administrativAmmount")} />
                <TextField
                    className="c-manager"
                    type="number"
                    InputLabelProps={{ shrink: true }}
                    label="Împrumut restituit"
                    onFocus={event => { event.target.select(); }}
                    value={transaction.imprumutPayment}
                    maxLength={100}
                    size="small"
                    onChange={e => this.handleChange(e, "imprumutPayment")} />
                <br />
                <div className="c-manager-div">
                    Metoda plății: &nbsp;&nbsp;<div className="blue inline">
                        <Select

                            className="c-manager-payment"
                            value={transaction.paymentMethod}
                            onChange={e => this.handleChange(e, "paymentMethod")}
                        >
                            <MenuItem value={0}>&nbsp;Casă</MenuItem>
                            <MenuItem value={1}>&nbsp;Bancă</MenuItem>
                        </Select></div>
                </div>
                <TextField
                    className="c-manager"
                    type="number"
                    InputLabelProps={{ shrink: true }}
                    label="Penalizare"
                    onFocus={event => { event.target.select(); }}
                    value={transaction.penalizare}
                    maxLength={100}
                    size="small"
                    onChange={e => this.handleChange(e, "penalizare")} />
                <br />
                <TextField
                    className="c-manager"
                    type="number"
                    InputLabelProps={{ shrink: true }}
                    label="Împrumut primit"
                    onFocus={event => { event.target.select(); }}
                    value={transaction.imprumutTaken}
                    maxLength={100}
                    size="small"
                    onChange={e => this.handleChange(e, "imprumutTaken")} />
                <TextField
                    className="c-manager"
                    type="number"
                    InputLabelProps={{ shrink: true }}
                    label="Număr luni împrumut"
                    onFocus={event => { event.target.select(); }}
                    value={transaction.monthCount_rate}
                    maxLength={100}
                    size="small"
                    onChange={e => this.handleChange(e, "monthCount_rate")} />
                <br />
                <TextField
                    className="c-manager"
                    type="number"
                    InputLabelProps={{ shrink: true }}
                    label="Dobândă"
                    onFocus={event => { event.target.select(); }}
                    value={transaction.dobandaEsalonata}
                    maxLength={100}
                    size="small"
                    onChange={e => this.handleChange(e, "dobandaEsalonata")} />
                <div className="c-manager-div">
                    Tipul Dobânzii: &nbsp;&nbsp;<div className="blue inline">
                        <Select

                            className="c-manager-payment"
                            value={transaction.interestType}
                            onChange={e => this.handleChange(e, "interestType")}
                        >
                            <MenuItem value={0}>&nbsp;Eșalonată</MenuItem>
                            <MenuItem value={1}>&nbsp;Anticipată</MenuItem>
                        </Select></div>
                </div>
                <br />
                <TextField
                    className="c-manager-long"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    label="Data lichidării"
                    onFocus={event => { event.target.select(); }}
                    value={transaction.liquidationDate}
                    size="small"
                    onChange={e => this.handleChange(e, "liquidationDate")} />
                <br />
                <Divider className="blue inline" textAlign="left">Total de plată</Divider>
                <br />
                <div className="c-manager-div">
                    Total de plată: &nbsp;&nbsp;<div className="blue inline">{
                        parseInt(transaction.cotizatieAmmount, 10)
                        + parseInt(transaction.imprimateAmmount, 10) + parseInt(transaction.contributieAmmount, 10) * parseInt(transaction.monthCount_cotiz, 10)
                        + parseInt(transaction.ajAmmount, 10) * parseInt(transaction.monthCount_cotiz, 10) + parseInt(transaction.culturalAmmount, 10)
                        + parseInt(transaction.administrativAmmount, 10) * parseInt(transaction.monthCount_cotiz, 10) + parseInt(transaction.imprumutPayment, 10)
                        + parseInt(transaction.penalizare, 10)
                    }</div>
                </div>
                <br />
                <Button className="c-button" variant="outlined" size="small" onClick={this.downloadFront}>Descarcă Fața</Button>
                <Button className="c-button" variant="outlined" size="small" onClick={this.downloadBack}>Descarcă Verso</Button>
                <Button className="c-button" variant="outlined" size="small" onClick={this.createTransaction}>Adaugă tranzacția</Button>

                <br />
                {error && <div className="red">Eroare: {error}</div>}
            </Box>
        </Modal>

        return (
            <div>
                <h1 className="title-left">Tranzacții - {currentContributer.name}</h1>
                {this.state.loadingState === config.LoadingStates.loading && <div>Se încarcă datele...</div>}
                {this.state.loadingState === config.LoadingStates.loaded && grid}
                {transactionModal}
                <br />
            </div>
        );
    };
}