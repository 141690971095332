import React, { Component } from "react";
import Swal from "sweetalert2";
import {
    Button,
    MenuItem,
    Select,
} from '@mui/material';

import { TextField } from '@material-ui/core';

import env from "../env";
import config from "../config";
import contributorsFunctions from "./contributorsGeneric";

export default class ContribuitorsManager extends Component {
    constructor(props) {
        super(props);
        let initialContributor = this.props.editContributer;
        if (!initialContributor) {
            initialContributor = {
                id: '',
                cnp: '',
                name: '',
                address: '',
                nrCupon: '',
                pensionAmmount: 0,
                registrationDate: '',
                officeNumber: 0,
                phoneNumber: '',
                dataPlatiiImprimate: '',
                costImprimate: 0,
                cotisationPayedUntil: '',
                cotisation_c: 0,
                cotisation_b: 0,
                cotisationAmmount: 0,
                contributionPayedUntil: '',
                contributionPaymentDate: '',
                contribution_c: 0,
                contribution_b: 0,
                culturalAmmount_c: 0,
                culturalAmmount_b: 0,
                culturalAmmountTotal: 0,
                administrativeAmmount_c: 0,
                administrativeAmmount_b: 0,
                administrativeAmmountTotal: 0,
                loanStartDate: '',
                loanAmmount: 0,
                loanMonthsCount: 0,
                monthlyLoanPayment: 0,
                loanLiquidationDate: '',
                yearlyInterestRate: 0,
                recievedInterest: 0,
                floatingInterest: 0,
                lastLoanPaymentDate: '',
                returnedLoanAmmount_c: 0,
                returnedLoanAmmount_b: 0,
                previousLoanPaymentDate: '',
                recalculatedInterestRate: 0,
                loanBalance: 0,
                interestDifference: 0,
                balneo: 0,
                stomatologie: 0,
                other: 0,
                operator: '',
                fadammount_c: 0,
                fadammount_b: 0,
                fadammountTotal: 0,
                ajammount_c: 0,
                ajammount_b: 0,
                ajammountTotal: 0,
                dataNasterii: '',
                penalizare: '',
                dobandaRetragere: '',
                dobandaDeces: '',
                status: 0,
            }
        }
        this.state = {
            loadingState: config.LoadingStates.loading,
            contributor: initialContributor,
        };

        this.handleChange = this.handleChange.bind(this);
        this.saveContributer = this.saveContributer.bind(this);
        this.createRequest = this.createRequest.bind(this);
        this.cancel = this.cancel.bind(this);
        this.isInvalidSave = this.isInvalidSave.bind(this);
        this.saveAdd = this.saveAdd.bind(this);
    }

    handleChange = (event, location) => {
        let contributor = this.state.contributor;
        contributor[location] = event.target.value;

        this.setState({
            ...this.state,
            contributor: contributor,
        });
    };

    isInvalidSave = () => {
        let { contributor } = this.state;
        let { editContributer } = this.props;
        let { usedIds } = this.props;
        let result = '';
        if (!editContributer) {
            if (!contributor.id) {
                result += 'ID'
            } else if (contributor.id <= 0) {
                result += 'ID (număr pozitiv)'
            } else if (usedIds.indexOf(contributor.id) >= 0) {
                result += 'ID unic (IDul este folosit deja)'
            }
        }
        if (!contributor.cnp) {
            if (result.length) { result += '; ' }
            result += 'CNP'
        }
        if (!contributor.dataNasterii) {
            if (result.length) { result += '; ' }
            result += 'data nașterii'
        } else if (contributor.cnp < 0) {
            if (result.length) { result += '; ' }
            result += 'CNP (număr pozitiv)'
        } else if (contributor.cnp && contributor.cnp.length !== 13) {
            if (result.length) { result += '; ' }
            result += 'CNP (13 cifre)'
        }
        if (!contributor.name) {
            if (result.length) { result += '; ' }
            result += 'nume'
        }
        if (!contributor.address) {
            if (result.length) { result += '; ' }
            result += 'adresă'
        }
        if (!contributor.officeNumber) {
            if (result.length) { result += '; ' }
            result += 'Nr casă'
        }
        if (!contributor.phoneNumber) {
            if (result.length) { result += '; ' }
            result += 'telefon'
        } else if (contributor.phoneNumber.length !== 10) {
            if (result.length) { result += '; ' }
            result += 'telefon (10 cifre)'
        }
        if (!contributor.address) {
            if (result.length) { result += '; ' }
            result += 'adresă'
        }
        return result;
    }

    saveAdd = () => {
        this.setState({
            ...this.state,
            loadingState: config.LoadingStates.loading
        });
        let url = env.ApiBaseUrl + "users";
        let contributor = JSON.stringify(this.state.contributor);
        fetch(url, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
                'cache-control': "no-cache"
            }),
            body: contributor,

        })
            .then(
                (result) => {
                    if (result.status === 200) {
                        console.log(result);
                        Swal.fire({
                            title: 'Salvare cu success',
                            text: 'utilizatorul a fost salvat cu success!',
                            icon: 'success',
                        });
                        this.props.showPage(config.DisplayingPage.view, contributor);
                    } else {
                        Swal.fire({
                            title: 'Eroare',
                            text: 'Utilizatorul nu a putut fi salvat!',
                            icon: 'error',
                        });
                    }
                },
                () => {
                    Swal.fire({
                        title: 'Eroare',
                        text: 'Utilizatorul nu a putut fi salvat!',
                        icon: 'error',
                    });
                }
            );
    }

    saveContributer = () => {
        let isInvalidSaveResult = this.isInvalidSave();
        if (isInvalidSaveResult) {
            Swal.fire({
                title: 'Eroare',
                text: "Vă rugăm să introduceți " + isInvalidSaveResult,
                icon: 'error',
            });
        } else {
            Swal.fire({
                title: "Vă rugăm să confirmați",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Da, confirm',
                cancelButtonText: "Anulează",
            }).then((result) => {
                if (result.isConfirmed) {
                    this.saveAdd();
                }
                else {
                    Swal.fire({
                        title: 'Anulare',
                        text: 'Acțiunea a fost anulată.',
                        icon: 'warning',
                    });
                    return;
                }
            });
        }
    }

    createRequest = () => {
        let isInvalidSaveResult = this.isInvalidSave();
        if (isInvalidSaveResult) {
            Swal.fire({
                title: 'Eroare',
                text: "Vă rugăm să introduceți " + isInvalidSaveResult,
                icon: 'error',
            });
        } else {

            let url = env.ApiBaseUrl + "documents/inscriere";
            let contributor = JSON.stringify(this.state.contributor);
            fetch(url, {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'cache-control': "no-cache"
                }),
                body: contributor,

            })
                .then(response => response.blob())
                .then(blob => {
                    // Create a URL for the blob
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    // Create a temporary link element
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = 'Cerere' + this.state.contributor.cnp + '.pdf';
                    // Append the link to the body
                    document.body.appendChild(a);
                    // Click the link
                    a.click();
                    // Remove the link from the body
                    document.body.removeChild(a);
                })
                .catch(error => {
                    console.error('Error downloading PDF:', error);
                });
        }
    }

    cancel = () => {
        let { editContributer } = this.props;
        this.props.showPage(config.DisplayingPage.view, editContributer);
    }

    componentDidMount() {
        let { usedIds, currentOperator } = this.props;
        let { contributor } = this.state;

        if (!contributor.id) {
            let idForAdd = 1;
            while (usedIds.indexOf(idForAdd) >= 0) {
                idForAdd++;
            }
            contributor.status = 0;
            contributor.id = idForAdd;
            contributor.registrationDate = new Date().toISOString().substring(0, 10);
        }

        contributor.officeNumber = currentOperator.casa;

        this.setState({
            ...this.state,
            loadingState: config.LoadingStates.loaded,
            contributor: contributor,
        });
    };

    render() {
        let { contributor } = this.state;
        let { editContributer } = this.props;
        const inputZone =
            <form autoComplete="off" >
                <TextField
                    className="c-manager"
                    id="standard-basic"
                    type="number"
                    label="ID"
                    value={contributor.id}
                    size="small"
                    InputProps={{
                        readOnly: editContributer && editContributer.id,
                    }}
                    onChange={e => this.handleChange(e, "id")} />
                <TextField
                    className="c-manager"
                    type="number"
                    label="CNP"
                    value={contributor.cnp}
                    maxLength={13}
                    size="small"
                    onChange={e => this.handleChange(e, "cnp")} />
                <br />
                <TextField
                    className="c-manager"
                    label="Nume"
                    value={contributor.name}
                    maxLength={100}
                    size="small"
                    onChange={e => this.handleChange(e, "name")} />
                <div className="c-manager-div">
                    Status: &nbsp;&nbsp;
                    <Select

                        className="c-manager-status"
                        value={contributor.status}
                        onChange={e => this.handleChange(e, "status")}
                    >
                        <MenuItem value={0}>&nbsp;{contributorsFunctions.getStatus(0)}</MenuItem>
                        <MenuItem value={1}>&nbsp;{contributorsFunctions.getStatus(1)}</MenuItem>
                        <MenuItem value={2}>&nbsp;{contributorsFunctions.getStatus(2)}</MenuItem>
                        <MenuItem value={3}>&nbsp;{contributorsFunctions.getStatus(3)}</MenuItem>
                        <MenuItem value={4}>&nbsp;{contributorsFunctions.getStatus(4)}</MenuItem>
                    </Select>
                </div>
                <br />
                <TextField
                    className="c-manager-long"
                    label="Adresă"
                    value={contributor.address}
                    maxLength={250}
                    size="small"
                    onChange={e => this.handleChange(e, "address")} />
                <br />
                <TextField
                    className="c-manager"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    label="0d1. Data nașterii"
                    value={contributor.dataNasterii}
                    size="small"
                    onChange={e => this.handleChange(e, "dataNasterii")} />
                <TextField
                    className="c-manager"
                    label="1d2. Nr. cupon pensie"
                    value={contributor.nrCupon}
                    maxLength={50}
                    size="small"
                    onChange={e => this.handleChange(e, "nrCupon")} />
                <br />
                <TextField
                    className="c-manager"
                    type="number"
                    label="2r3. Pensie lunară"
                    value={contributor.pensionAmmount}
                    size="small"
                    onChange={e => this.handleChange(e, "pensionAmmount")} />
                <TextField
                    className="c-manager"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    label="3D4. Data înscrierii în CARP"
                    value={contributor.registrationDate?.split('-').reverse().join('-') ?? ''}
                    size="small"
                    onChange={e => this.handleChange(e, "registrationDate")} />
                <br />
                <TextField
                    className="c-manager"
                    type="number"
                    label="4r5. Nr casă"
                    value={contributor.officeNumber}
                    size="small"
                    onChange={e => this.handleChange(e, "officeNumber")} />
                <TextField
                    className="c-manager"
                    label="5r11. Telefon"
                    value={contributor.phoneNumber}
                    maxLength={10}
                    size="small"
                    onChange={e => this.handleChange(e, "phoneNumber")} />
                <br />
                <TextField
                    className="c-manager"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    label="6dx1. Data plății imprimate"
                    value={contributor.dataPlatiiImprimate?.split('-').reverse().join('-') ?? ''}
                    size="small"
                    onChange={e => this.handleChange(e, "dataPlatiiImprimate")} />
                <TextField
                    className="c-manager"
                    type="number"
                    label="7r10. Cost imprimate"
                    value={contributor.costImprimate}
                    size="small"
                    onChange={e => this.handleChange(e, "costImprimate")} />
                <br />
                <TextField
                    className="c-manager"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    label="8D6. Până când s-a plătit cotizația"
                    value={contributor.cotisationPayedUntil?.split('-').reverse().join('-') ?? ''}
                    maxLength={13}
                    size="small"
                    onChange={e => this.handleChange(e, "cotisationPayedUntil")} />
                <TextField
                    className="c-manager"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    label="9D7. Data incasării ultimei cotizații"
                    value={contributor.cotisationLastDate?.split('-').reverse().join('-') ?? ''}
                    size="small"
                    onChange={e => this.handleChange(e, "cotisationLastDate")} />
                <br />
                <TextField
                    className="c-manager"
                    type="number"
                    label="10r8. Cotizația încasată casă"
                    value={contributor.cotisation_c}
                    maxLength={13}
                    size="small"
                    onChange={e => this.handleChange(e, "cotisation_c")} />
                <TextField
                    className="c-manager"
                    type="number"
                    label="11r9. Cotizația încasată bancă"
                    value={contributor.cotisation_b}
                    size="small"
                    onChange={e => this.handleChange(e, "cotisation_b")} />
                <br />
                <TextField
                    className="c-manager"
                    type="number"
                    label="12r12. Sold cotizație"
                    value={contributor.cotisationAmmount}
                    maxLength={13}
                    size="small"
                    onChange={e => this.handleChange(e, "cotisationAmmount")} />
                <TextField
                    className="c-manager"
                    type="number"
                    label="13. Suma contribuții casă"
                    value={contributor.contribution_c}
                    size="small"
                    onChange={e => this.handleChange(e, "contribution_c")} />
                <br />
                <TextField
                    className="c-manager"
                    type="number"
                    label="14. Suma contribuții bancă"
                    value={contributor.contribution_b}
                    size="small"
                    onChange={e => this.handleChange(e, "contribution_b")} />
                <TextField
                    className="c-manager"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    label="15D14. Până când s-au plătit contribuții"
                    value={contributor.contributionPayedUntil?.split('-').reverse().join('-') ?? ''}
                    size="small"
                    onChange={e => this.handleChange(e, "contributionPayedUntil")} />
                <br />
                <TextField
                    className="c-manager"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    label="16D15. Data ultimei încasări contribuții"
                    value={contributor.contributionPaymentDate?.split('-').reverse().join('-') ?? ''}
                    size="small"
                    onChange={e => this.handleChange(e, "contributionPaymentDate")} />
                <TextField
                    className="c-manager"
                    type="number"
                    label="17r16. Încasat FAD casă"
                    value={contributor.fadammount_c}
                    size="small"
                    onChange={e => this.handleChange(e, "fadammount_c")} />
                <br />
                <TextField
                    className="c-manager"
                    type="number"
                    label="18r17. Încasat FAD bancă"
                    value={contributor.fadammount_b}
                    size="small"
                    onChange={e => this.handleChange(e, "fadammount_b")} />
                <TextField
                    className="c-manager"
                    type="number"
                    label="19rx3. Total Încasari FAD"
                    value={contributor.fadammountTotal}
                    size="small"
                    onChange={e => this.handleChange(e, "fadammountTotal")} />
                <br />
                <TextField
                    className="c-manager"
                    type="number"
                    label="Dobândă deces"
                    value={contributor.dobandaDeces}
                    size="small"
                    onChange={e => this.handleChange(e, "dobandaDeces")} />
                <TextField
                    className="c-manager"
                    type="number"
                    label="20rx1. Încasat Aj. social (casă)"
                    value={contributor.ajammount_c}
                    size="small"
                    onChange={e => this.handleChange(e, "ajammount_c")} />
                <br />
                <TextField
                    className="c-manager"
                    type="number"
                    label="21rz1. Încasat Aj. social (bancă)"
                    value={contributor.ajammount_b}
                    size="small"
                    onChange={e => this.handleChange(e, "ajammount_b")} />
                <TextField
                    className="c-manager"
                    type="number"
                    label="22rx5. Total Aj. social"
                    value={contributor.ajammountTotal}
                    size="small"
                    onChange={e => this.handleChange(e, "ajammountTotal")} />
                <br />
                <TextField
                    className="c-manager"
                    type="number"
                    label="23rx2. Încasat cultural (casă)"
                    value={contributor.culturalAmmount_c}
                    size="small"
                    onChange={e => this.handleChange(e, "culturalAmmount_c")} />

                <TextField
                    className="c-manager"
                    type="number"
                    label="24ry2. Încasat cultural (bancă)"
                    value={contributor.culturalAmmount_b}
                    size="small"
                    onChange={e => this.handleChange(e, "culturalAmmount_b")} />
                <br />
                <TextField
                    className="c-manager"
                    type="number"
                    label="25rx6. Total cultural"
                    value={contributor.culturalAmmountTotal}
                    size="small"
                    onChange={e => this.handleChange(e, "culturalAmmountTotal")} />
                <TextField
                    className="c-manager"
                    type="number"
                    label="26r18. Cheltuieli administrative (casă)"
                    value={contributor.administrativeAmmount_c}
                    size="small"
                    onChange={e => this.handleChange(e, "administrativeAmmount_c")} />
                <br />
                <TextField
                    className="c-manager"
                    type="number"
                    label="27ry3. Cheltuieli administrative (bancă)"
                    value={contributor.administrativeAmmount_b}
                    size="small"
                    onChange={e => this.handleChange(e, "administrativeAmmount_b")} />
                <TextField
                    className="c-manager"
                    type="number"
                    label="28rx4. Total cheltuieli administrative"
                    value={contributor.administrativeAmmountTotal}
                    size="small"
                    onChange={e => this.handleChange(e, "administrativeAmmountTotal")} />
                <br />
                <TextField
                    className="c-manager"
                    type="number"
                    InputLabelProps={{ shrink: true }}
                    label="Penalizare"
                    value={contributor.penalizare}
                    size="small"
                    onChange={e => this.handleChange(e, "penalizare")} />
                <TextField
                    className="c-manager"
                    type="number"
                    label="Dobândă retragere"
                    value={contributor.dobandaRetragere}
                    size="small"
                    onChange={e => this.handleChange(e, "dobandaRetragere")} />
                <br />
                <TextField
                    className="c-manager"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    label="29D20. Data acordării împrumutului"
                    value={contributor.loanStartDate?.split('-').reverse().join('-') ?? ''}
                    size="small"
                    onChange={e => this.handleChange(e, "loanStartDate")} />
                <TextField
                    className="c-manager"
                    type="number"
                    label="30r21. Împrumutul acordat"
                    value={contributor.loanAmmount}
                    size="small"
                    onChange={e => this.handleChange(e, "loanAmmount")} />
                <br />
                <TextField
                    className="c-manager"
                    type="number"
                    label="31r22. Nr. rate lunare"
                    value={contributor.loanMonthsCount}
                    size="small"
                    onChange={e => this.handleChange(e, "loanMonthsCount")} />
                <TextField
                    className="c-manager"
                    type="number"
                    label="32r23. Valoarea unei rate"
                    value={contributor.monthlyLoanPayment}
                    size="small"
                    onChange={e => this.handleChange(e, "monthlyLoanPayment")} />
                <br />
                <TextField
                    className="c-manager"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    label="33D24. Data lichidării împrumutului"
                    value={contributor.loanLiquidationDate?.split('-').reverse().join('-') ?? ''}
                    size="small"
                    onChange={e => this.handleChange(e, "loanLiquidationDate")} />
                <TextField
                    className="c-manager"
                    type="number"
                    label="34r25. Dobânda anuală percepută %"
                    value={contributor.yearlyInterestRate}
                    size="small"
                    onChange={e => this.handleChange(e, "yearlyInterestRate")} />
                <br />
                <TextField
                    className="c-manager"
                    type="number"
                    label="35r26. Dobânda reținută"
                    value={contributor.recievedInterest}
                    size="small"
                    onChange={e => this.handleChange(e, "recievedInterest")} />
                <TextField
                    className="c-manager"
                    type="number"
                    label="36ry1. Dobândă eșalonată"
                    value={contributor.floatingInterest}
                    size="small"
                    onChange={e => this.handleChange(e, "floatingInterest")} />
                <br />
                <TextField
                    className="c-manager"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    label="37D27. Data ultimei restituiri"
                    value={contributor.lastLoanPaymentDate?.split('-').reverse().join('-') ?? ''}
                    size="small"
                    onChange={e => this.handleChange(e, "lastLoanPaymentDate")} />
                <TextField
                    className="c-manager"
                    type="number"
                    label="38r28. Restituit împrumut (casă)"
                    value={contributor.returnedLoanAmmount_c}
                    size="small"
                    onChange={e => this.handleChange(e, "returnedLoanAmmount_c")} />
                <br />
                <TextField
                    className="c-manager"
                    type="number"
                    label="39r29. Restituit împrumut (bancă)"
                    value={contributor.returnedLoanAmmount_b}
                    size="small"
                    onChange={e => this.handleChange(e, "returnedLoanAmmount_b")} />
                <TextField
                    className="c-manager"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    label="40D30. Data încasării rate precedente"
                    value={contributor.previousLoanPaymentDate?.split('-').reverse().join('-') ?? ''}
                    size="small"
                    onChange={e => this.handleChange(e, "previousLoanPaymentDate")} />
                <br />
                <TextField
                    className="c-manager"
                    type="number"
                    label="41r31. Dobânda recalculată"
                    value={contributor.recalculatedInterestRate}
                    size="small"
                    onChange={e => this.handleChange(e, "recalculatedInterestRate")} />
                <TextField
                    className="c-manager"
                    type="number"
                    label="42r32. Sold împrumut"
                    value={contributor.loanBalance}
                    size="small"
                    onChange={e => this.handleChange(e, "loanBalance")} />
                <br />
                <TextField
                    className="c-manager"
                    type="number"
                    label="43rz2. Diferență dobândă"
                    value={contributor.interestDifference}
                    size="small"
                    onChange={e => this.handleChange(e, "interestDifference")} />
                <TextField
                    className="c-manager"
                    type="number"
                    label="44s44. Balneo"
                    value={contributor.balneo}
                    size="small"
                    onChange={e => this.handleChange(e, "balneo")} />
                <br />
                <TextField
                    className="c-manager"
                    type="number"
                    label="45s45. Stomatologie"
                    value={contributor.stomatologie}
                    size="small"
                    onChange={e => this.handleChange(e, "stomatologie")} />
                <TextField
                    className="c-manager"
                    type="number"
                    label="46s46. Alte ajutoare"
                    value={contributor.other}
                    size="small"
                    onChange={e => this.handleChange(e, "other")} />

            </form>
        return (
            <div>
                <h1 className="title">{editContributer && editContributer.id ? "Editează contribuitorul" : "Adaugă contribuitor nou"} - casa {this.props.currentOperator.casa}</h1>
                {editContributer && editContributer.id &&
                    <Button className="c-button" variant="outlined" onClick={this.saveContributer}>Salvează </Button>}
                {!(editContributer && editContributer.id) &&
                    <Button className="c-button" variant="outlined" onClick={this.saveContributer}>Adaugă contribuitor nou</Button>}
                {!(editContributer && editContributer.id) &&
                    <Button className="c-button" variant="outlined" onClick={this.createRequest}>Cerere înscriere</Button>}
                <Button className="c-button" variant="outlined" color='secondary' onClick={this.cancel}>Anulează operația </Button>
                {this.state.loadingState === config.LoadingStates.loading && <div>Se încarcă datele...</div>}
                {this.state.loadingState === config.LoadingStates.loaded && inputZone}
            </div>
        );
    };
}